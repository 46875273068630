<template>
  <ModalMain @close="onClose">
    <div class="px-4 md:px-7 pt-2 w-full text-center">
      <h1 class="text-2xl tracking-wide font-semibold mb-2 text-center">Verification Code</h1>
      <p class="text-slate-200 mt-4 leading-tight">Please check your email for the verification code</p>

      <div class="max-w-[370px] w-full mx-auto mt-3 inline-block">
        <InputCode
          ref="CodeInput"
          class="mt-3"
          :length="codeLength"
          :disabled="isLoading"
          @update="codeUpdate"
        />
      </div>

      <p
        v-show="errorMessage !== ''"
        class="w-full inline-block text-red text-sm mt-4"
      >
        {{ errorMessage }}
      </p>

      <button
        type="submit"
        class="text-sm tracking-widest mt-4 p-2 inline-block transition-opacity hover:opacity-90"
        :class="{'opacity-40 hover:opacity-40' : isLoading}"
        :disabled="isLoading"
        @click.prevent.stop="resendCode"
      >
        {{ resendBtnLabel }}
      </button>
    </div>
  </ModalMain>
</template>

<script>
import { mapActions } from 'pinia';
import { useAuthStore } from '@/store/auth';
import { useUserStore } from '@/store/user';
const RESEND_BTN_LABEL_DEFAULT = 'Send another code';

export default defineComponent({
  name: 'ModalVerificationCode',
  props: {
    attribute: {
      type: String,
      required: true,
    },
  },
  emits: [
    'close',
  ],
  setup() {
    const authStore = useAuthStore();
    return { authStore, };
  },
  data() {
    return {
      code: '',
      resendBtnLabel: RESEND_BTN_LABEL_DEFAULT,
      isLoading: false,
      errorMessage: '',
      codeLength: 6,
      codeInputDisabled: false,
    };
  },
  watch: {
    async code(val) {
      if (val.length === this.codeLength) { await this.confirm(); }
    },
  },
  methods: {
    ...mapActions(useAuthStore, ['verifyAttribute', 'verifyAttributeCode',]),
    ...mapActions(useUserStore, ['syncUserProfile',]),
    codeUpdate(val) {
      this.code = val;
    },
    async resendCode() {
      this.isLoading = true;
      this.resendBtnLabel = 'Sending...';
      this.resetInput();
      this.errorMessage = '';
      try {
        await this.verifyAttribute(this.attribute);
        this.resendBtnLabel = RESEND_BTN_LABEL_DEFAULT;
      } catch (err) {
        useNuxtApp().$rollbar.error('error resending code:', err);
      } finally {
        this.code = '';
        this.isLoading = false;
      }
    },
    onClose() {
      this.$emit('close');
    },
    async confirm() {
      this.isLoading = true;
      try {
        await this.verifyAttributeCode(this.attribute, this.code);
        await this.syncUserProfile();
        this.$gtmCustomEvent({ event: `verify_${this.attribute}`, action: 'success', });
        this.$emit('close');
      } catch (err) {
        this.errorMessage = err.message;
        this.resetInput();
      } finally {
        this.isLoading = false;
      }
    },
    resetInput() {
      this.code = '';
      this.$refs.CodeInput?.init();
    },
  },
});
</script>
